import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from '@mui/material';
import { User } from 'firebase/auth';
import has from 'lodash/has';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { object, type ObjectSchema, string } from 'yup';

import {
  FullLoader,
  Error,
  RHFAutoCompleteVirtualize,
  FormContainer,
} from '@openx/components/core';
import { Shape } from '@openx/types';

import { getEnvironmentConfigs } from '../../../environmentConfigs';
import { useAuth } from '../components/AuthContext';
import { SsoLayout } from '../components/SsoLayout';
import { InstanceListOption } from '../types';
import {
  fetchConsentResponse,
  getOAuthRedirectURL,
  getOAuthParams,
  setSearchParam,
  useFetchSessionInfo,
} from '../utils';

import { ApplicationInfo } from './ApplicationInfo';

interface FormValues {
  instance: InstanceListOption | null;
  sessionId: string | null;
  scopes: { [key: string]: boolean };
}

export const REQUIRED_SCOPES = new Set(['openid']);

const validationSchema = object({
  instance: object<null, Shape<InstanceListOption>>()
    .nullable()
    .required('Please select an instance')
    .test({
      message: 'Please select an instance',
      name: 'instance',
      test(value) {
        if (!value || !has(value, 'id')) {
          return false;
        }
        return true;
      },
    }),
  scopes: object().test({
    message: 'Scopes should be set if session exists',
    name: 'scopes',
    test(value, ctx) {
      if (!ctx.parent.sessionId) {
        return true;
      }

      for (const requiredScope of REQUIRED_SCOPES.keys()) {
        if (!value[requiredScope]) {
          return ctx.createError({
            message: `Required scope '${requiredScope}' is missing`,
          });
        }
      }
      return true;
    },
  }),
  sessionId: string().min(1).nullable().required(),
}) as ObjectSchema<FormValues>;

export function Consent() {
  const { requireConsent } = getEnvironmentConfigs();
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser, signOut } = useAuth();
  const { sessionId } = getOAuthParams(location);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isSelectedAutomatically, setIsSelectedAutomatically] =
    useState<boolean>(false);

  const { loading, registered, error, instancesList, session } =
    useFetchSessionInfo();

  const [isLoading, setIsLoading] = useState<boolean>(loading);

  const formMethods = useForm<FormValues>({
    defaultValues: {
      instance: null,
      scopes: {},
      sessionId: null,
    },
    resolver: yupResolver<FormValues>(validationSchema),
  });

  const onLogin = useCallback(async () => {
    try {
      await signOut();
    } catch (error) {
      enqueueSnackbar(`Error: "${error.message}".`, {
        variant: 'error',
      });
    } finally {
      navigate({
        hash: location.hash,
        pathname: '/login',
        search: setSearchParam(location, {
          forceLogin: true,
        }),
      });
    }
  }, [signOut, enqueueSnackbar, navigate, location]);

  const onNoSessionLogin = useCallback(
    (values: { instance: InstanceListOption }) => {
      setIsDisabled(true);
      try {
        if (!values.instance) {
          enqueueSnackbar('No instance selected', { variant: 'error' });
          return;
        }

        const user = currentUser as User;
        const email = user.email as string;

        const url = new URL(values.instance.urls[0]);
        url.pathname = '/login';
        url.searchParams.set('email', email);
        window.location.href = url.toString();
      } catch (error) {
        enqueueSnackbar(`Error: "${error.message}". Try reloading the page`, {
          variant: 'error',
        });
      } finally {
        setIsDisabled(false);
      }
    },
    [currentUser, enqueueSnackbar],
  );

  const onConsent = useCallback(
    async (values: FormValues): Promise<void> => {
      setIsDisabled(true);
      try {
        if (!values.instance) {
          enqueueSnackbar('No instance selected', { variant: 'error' });
          return;
        }
        const response = await fetchConsentResponse({
          currentUser: currentUser as User,
          formParams: {
            consent: true,
            instanceUID: values.instance.id,
            scope: Object.entries(values.scopes)
              .filter(([_scope, enabled]) => enabled)
              .map(([scope]) => scope)
              .join(' '),
            sessionId,
          },
        });

        window.location.href = getOAuthRedirectURL(response);
      } catch (error) {
        enqueueSnackbar(`Error: "${error.message}". Try reloading the page`, {
          variant: 'error',
        });
      } finally {
        setIsDisabled(false);
      }
    },
    [currentUser, enqueueSnackbar, sessionId],
  );

  const onDissent = useCallback(async () => {
    setIsDisabled(true);
    try {
      const response = await fetchConsentResponse({
        currentUser: currentUser as User,
        formParams: {
          consent: false,
          sessionId,
        },
      });

      window.location.href = getOAuthRedirectURL(response);
    } catch (error) {
      enqueueSnackbar(`Error: "${error.message}". Try reloading the page`, {
        variant: 'error',
      });
    } finally {
      setIsDisabled(false);
    }
  }, [currentUser, sessionId, enqueueSnackbar]);

  const onSubmit: SubmitHandler<FormValues> = async data => {
    if (!data.instance) {
      return;
    }
    if (!data.sessionId) {
      onNoSessionLogin(data as { instance: InstanceListOption });
      return;
    }
    await onConsent(data);
  };

  useEffect(() => {
    let instance: InstanceListOption | null = null;

    if (session) {
      if (instancesList && (session.instance_uid || session.redirect_uri)) {
        const selectedInstance = instancesList.find(({ id, urls }) => {
          if (id === session.instance_uid) {
            return true;
          }

          return urls?.some(url => session.redirect_uri?.includes(url));
        });

        if (selectedInstance) {
          setIsLoading(true);
          setIsSelectedAutomatically(true);

          instance = selectedInstance;
        }
      }

      formMethods.reset({
        instance,
        scopes: session.scope.split(' ').reduce<{
          [key: string]: boolean;
        }>((acc, scope) => ({ ...acc, [scope]: true }), {}),
        sessionId,
      });
    }

    const tryAutoConsent = async () => {
      if (
        !requireConsent &&
        session &&
        instancesList &&
        (instancesList.length === 1 || instance)
      ) {
        await onConsent({
          instance: instance ?? instancesList[0],
          scopes: session.scope.split(' ').reduce<{
            [key: string]: boolean;
          }>((acc, scope) => ({ ...acc, [scope]: true }), {}),
          sessionId,
        });
      }
    };
    tryAutoConsent();
  }, [
    instancesList,
    requireConsent,
    sessionId,
    session,
    onConsent,
    formMethods,
  ]);

  useEffect(() => {
    if (!loading && !isSelectedAutomatically) {
      setIsLoading(false);
    }
  }, [loading, isSelectedAutomatically]);

  useEffect(() => {
    if (!error) {
      return;
    }

    enqueueSnackbar(error.message, {
      variant: 'error',
    });

    if (error.type === 'authorization') {
      onLogin();
    }
  }, [error, loading, enqueueSnackbar, onLogin, isSelectedAutomatically]);

  if (isLoading) {
    return <FullLoader />;
  }

  if (!currentUser) {
    return <Navigate to={`/login${location.search}${location.hash}`} />;
  }

  if (!registered || !instancesList || !instancesList.length) {
    return (
      <Error
        title="Not registered"
        titlePrefix="OpenX"
        subtitle="Something went wrong or you are not registered"
        actions={[
          {
            children: 'Contact support',
            variant: 'contained',
          },
        ]}
      ></Error>
    );
  }

  return (
    <SsoLayout>
      <FormProvider {...formMethods}>
        <form
          onSubmit={formMethods.handleSubmit(onSubmit)}
          data-test="consent-form"
        >
          <FormContainer fullPage>
            <Typography variant="h3" gutterBottom data-test="current-user">
              You are now logged in as {currentUser.email}
            </Typography>

            <Typography
              variant="h3"
              gutterBottom
              data-test="authorize-app-text"
            >
              Select instance
            </Typography>
            <RHFAutoCompleteVirtualize
              name="instance"
              textFieldProps={{ label: 'Select instance' }}
              disableClearable
              renderOptions={{
                dataTest: 'instance-item',
              }}
              sx={{ marginBottom: 4, marginTop: 2, width: 300 }}
              getOptionLabel={option => option.label || ''}
              isOptionEqualToValue={(option, value) => {
                if (typeof value === 'string') {
                  return value === option.label;
                }
                return value.id === option.id;
              }}
              options={instancesList ?? []}
              data-test="instance-list"
            />

            {session && requireConsent && (
              <>
                <ApplicationInfo
                  appName={session.app_name}
                  clientId={session.client_id}
                  developerEmail={session.developer_email}
                ></ApplicationInfo>

                <Typography variant="h3" gutterBottom data-test="scopes-text">
                  Scopes
                </Typography>
                <FormGroup>
                  {session.scope.split(' ').map((scope, index) => (
                    <FormControlLabel
                      key={index}
                      label={scope}
                      data-test={scope}
                      control={
                        <Controller
                          name={`scopes.${scope}`}
                          control={formMethods.control}
                          render={({ field }) => (
                            <>
                              <Checkbox
                                defaultChecked
                                disabled={REQUIRED_SCOPES.has(scope)}
                                {...field}
                              ></Checkbox>
                            </>
                          )}
                        ></Controller>
                      }
                    ></FormControlLabel>
                  ))}
                </FormGroup>
              </>
            )}
          </FormContainer>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            marginTop={4}
          >
            <Box alignSelf="flex-start">
              <Button
                type="button"
                variant="text"
                data-test="logout-button"
                onClick={onLogin}
                disabled={isDisabled}
              >
                Log out
              </Button>
            </Box>
            <Box flexGrow="1"> </Box>
            {requireConsent && session && (
              <Box alignSelf="flex-end">
                <Button
                  type="button"
                  variant="text"
                  data-test="deny-button"
                  onClick={onDissent}
                  disabled={isDisabled}
                >
                  Deny
                </Button>
              </Box>
            )}
            <Box alignSelf="flex-end">
              <Button
                type="submit"
                color="primary"
                disabled={
                  !formMethods.formState.isValid ||
                  formMethods.formState.isSubmitting ||
                  isDisabled
                }
                data-test="authorize-button"
              >
                Authorize
              </Button>
            </Box>
          </Stack>
        </form>
      </FormProvider>
    </SsoLayout>
  );
}
